.container {
  background-color: #f6f7fa;
  min-width: 100vw;
  min-height: 100vh;
  text-align: justify;
  line-height: 1.5;
  display: inline;

  .header {
    text-align: center;
    font-size: 15pt;
    padding-top: 25px;
    padding-bottom: 50px;
    box-shadow: 0 2px 16px 0 rgba(17, 17, 17, 0.08);
    background-color: black;
    color: #f7b500;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .warning {
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #f7b500;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .landing {
    padding-left: 10%;
    padding-right: 10%;

    .head-title {
      font-size: 20px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      color: #111;
      display: flex;
      justify-content: center;
    }

    .content-landing {
      padding-left: 40px;
      padding-right: 5%;
      font-size: 1rem;
    }
  }

  .container-aggree {
    text-align: center;
    margin: 30px 0px;

    .button-aggree {
      align-items: center;
      height: 60px;
      background-color: #fc0;
      border: none;
      font-weight: 700;
      /* line-height: 1.53; */
      font-size: 20px;
      padding: 20px 30px;
      /* padding-left: 20px; */
      border-radius: 5px;
    }
  }
}

.my20px {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my10px {
  margin-top: 10px;
  margin-bottom: 10px;
}

.footer {
  background-color: #000;
  color: #fff;
  font-size: 0.9rem;
  text-align: center;
  padding: 30px 0 25px;
}